<template>
  <div ref="root" class="failed-icon">
    <svg height="47.77" viewBox="0 0 47.771 47.77" width="47.771">
      <path
        d="m39.627 46.373-15.742-15.741-15.742 15.741a4.77 4.77 0 0 1 -6.743-6.746l15.739-15.741-15.739-15.741a4.771 4.771 0 0 1 6.745-6.745l15.741 15.739 15.741-15.739a4.77 4.77 0 0 1 6.745 6.746l-15.74 15.74 15.742 15.74a4.771 4.771 0 0 1 -6.747 6.747z"
        fill="#fff"
      />
    </svg>
    <div class="text">Good luck !</div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es';

export default {
  props: {
    isPlay: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isPlay(newVal) {
      if (newVal) {
        this.animate();
      }
    },
  },
  methods: {
    animate() {
      const xMax = 16;
      anime({
        targets: this.$refs.root,
        easing: 'easeInOutSine',
        duration: 550,
        translateX: [{ value: xMax * -1 }, { value: xMax }, { value: xMax / -2 }, { value: xMax / 2 }, { value: 0 }],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-bg: #ff6262;
$main-color: #ffffff;

.failed-icon {
  position: relative;
  width: rem(170);
  height: rem(170);
  padding-top: rem(14);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: $icon-bg;
  border: none;
  border-radius: 50%;

  .x-icon {
    width: rem(48);
    height: rem(48);
    fill: $main-color;
  }

  .text {
    margin-top: rem(12);
    color: $main-color;
    font-size: rem(14);
    font-family: 'Fjalla One', sans-serif;
  }
}
</style>
