import sum from 'lodash/sum';

export const types = {
  SET_SUBJECTS: 'SET_SUBJECTS',
  SET_UNITS: 'SET_UNITS',
};

export const initialState = {
  subjects: [],
  units: [],
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    subjects: (state) => state.subjects,
    units: (state) => state.units,
    answeredRate: (state) => {
      const answeredRates = state.subjects.filter((x) => x.answered_rate > 0).map((x) => x.answered_rate);
      if (answeredRates.length === 0) return 0;
      return Math.round(sum(answeredRates) / answeredRates.length, 2);
    },
    getAnsweredRateBySubjectId:
      (state, { subjects }) =>
      (subjectId) => {
        if (subjects.length === 0) return 0;
        const subject = subjects.find((x) => x.sbj_id === subjectId);
        if (subject === undefined) return 0;
        return subject.answered_rate !== undefined ? Math.round(subject.answered_rate) : 0;
      },
    getAnsweredRateByUnitId:
      (state, { units }) =>
      (unitId) => {
        if (units.length === 0) return 0;
        const unit = units.find((x) => x.unit_id === unitId);
        if (unit === undefined) return 0;
        return unit.answered_rate !== undefined ? Math.round(unit.answered_rate) : 0;
      },
    getUnAnsweredRateByUnitId:
      (state, { units }) =>
      (unitId) => {
        if (units.length === 0) return 100;
        const unit = units.find((x) => x.unit_id === unitId);
        if (unit === undefined) return 100;
        return unit.unanswered_rate !== undefined ? Math.round(unit.unanswered_rate) : 100;
      },
    getWeakRateByUnitId:
      (state, { units }) =>
      (unitId) => {
        if (units.length === 0) return 0;
        const unit = units.find((x) => x.unit_id === unitId);
        if (unit === undefined) return 0;
        return unit.weak_rate !== undefined ? Math.round(unit.weak_rate) : 0;
      },
  },
  actions: {
    setSubjects({ commit }, { subjects }) {
      commit(types.SET_SUBJECTS, { subjects });
    },
    setUnits({ commit }, { units }) {
      commit(types.SET_UNITS, { units });
    },
  },
  mutations: {
    [types.SET_SUBJECTS](state, { subjects }) {
      state.subjects = [...subjects];
    },
    [types.SET_UNITS](state, { units }) {
      state.units = [...units];
    },
  },
};
