export const types = {
  SET_LOADING: 'SET_LOADING',
};

export const initialState = {
  isLoading: false,
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    isLoading: (state) => state.isLoading,
  },
  actions: {
    setLoading({ commit }, isLoading) {
      commit(types.SET_LOADING, isLoading);
    },
  },
  mutations: {
    [types.SET_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
  },
};
