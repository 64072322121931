<template>
  <div class="success-icon">
    <span class="effect-1" :class="{ 'is-play': isPlay }"></span>
    <span class="effect-2" :class="{ 'is-play': isPlay }"></span>
    <!-- <div class="dp" :class="{ 'is-play': isPlay }">
      <b class="number">+3</b>
      <span class="unit">DP</span>
    </div> -->
    <div class="circle-wrapper">
      <div class="circle"></div>
    </div>
    <div class="text">GREAT !</div>
  </div>
</template>

<script>
export default {
  props: {
    isPlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-bg: #4066ea;
$main-color: #ffffff;

%effect-template {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;

  border: 2px solid $icon-bg;
  border-radius: 50%;

  transition-property: opacity, width, height;
  transition-duration: 0.8s;
  transition-timing-function: ease-out;

  transform: translateY(-50%);

  &.is-play {
    width: 130%;
    height: 130%;
    opacity: 0;
  }
}

.success-icon {
  position: relative;
  width: rem(170);
  height: rem(170);
  padding-top: rem(10); // DP非表示時

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: $icon-bg;
  border: none;
  border-radius: 50%;

  text-align: center;

  .circle-wrapper {
    width: rem(62);
    height: rem(62);
    margin: 0 auto;

    background: $main-color;
    border: none;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      width: rem(39);
      height: rem(39);

      background: $icon-bg;
      border: none;
      border-radius: 50%;
    }
  }

  .text {
    margin-top: rem(8); // DP非表示時
    // margin: rem(8) 0 rem(15);
    color: $main-color;
    font-size: rem(14);
    font-family: 'Fjalla One', sans-serif;
    line-height: 1;
    letter-spacing: 0.01em;
  }

  .dp {
    margin-bottom: rem(8);
    padding-left: 0.75em;
    color: $main-color;
    line-height: 1;

    transition-property: opacity, transform;
    transition-duration: 0.8s;
    transition-timing-function: ease-out;

    &.is-play {
      opacity: 0;
      transform: translateY(-50%);
    }

    .number {
      font-size: rem(18);
      font-family: 'Fjalla One', sans-serif;
    }

    .unit {
      font-size: rem(12);
      font-family: 'Proxima Nova', sans-serif;
    }
  }

  .effect-1 {
    @extend %effect-template;
  }

  .effect-2 {
    @extend %effect-template;
    transition-duration: 0.6s;
    &.is-play {
      width: 150%;
      height: 150%;
      opacity: 0;
    }
  }
}
</style>
