import CryptoJS from 'crypto-js';

const secretKey = process.env.VUE_APP_SECRET_KEY_HASH;

/**
 * LocalStorageにデータを保存
 * @param {string} key
 * @param {any} value
 */
export const save = (key, value) => {
  const jsonString = JSON.stringify(value);
  localStorage.setItem(key, jsonString);
};

/**
 * LocalStorageからデータを取得
 * @param {string} key
 */
export const load = (key) => {
  const jsonString = localStorage.getItem(key);
  return JSON.parse(jsonString);
};

/**
 * LocalStorageかっらデータを削除
 * @param {string} key
 */
export const remove = (key) => localStorage.removeItem(key);

/**
 * LocalStorageに暗号化したデータを保存
 * @param {string} key
 * @param {any} value
 */
export const secretSave = (key, value) => {
  const jsonString = JSON.stringify(value);
  localStorage.setItem(key, CryptoJS.AES.encrypt(jsonString, secretKey).toString());
};

/**
 * LocalStorageから暗号化したデータを取得
 * @param {string} key
 */
export const secretLoad = (key) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData === null) return null;

  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const jsonString = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(jsonString);
};

export default {
  save,
  load,
  remove,
  secretSave,
  secretLoad,
};
