import { secretSave, secretLoad, remove } from '@/storage/local';
import * as cognito from '@/aws/cognito';

const STORAGE_KEY = 'Session';

export async function signUp(email, password, firstName, lastName, universityId, universityGrade) {
  return cognito.signUp(email, password, firstName, lastName, universityId, universityGrade);
}

export async function signOut() {
  cognito.signOut();
  remove(STORAGE_KEY);
  return;
}

export async function signIn(email, password) {
  const cognitoUserSession = await cognito.signIn(email, password);
  secretSave(STORAGE_KEY, cognitoUserSession);
  return cognitoUserSession;
}

export function getSession() {
  return secretLoad(STORAGE_KEY);
}

export function setSession(session) {
  secretSave(STORAGE_KEY, session);
}

export function emailVerify(email, code) {
  return cognito.verifyCode(email, code);
}

export function resendVerifyCode(email) {
  return cognito.resendVerifyCode(email);
}

export async function forgotPassword(email) {
  return cognito.forgotPassword(email);
}

export async function resetPassword(email, password, code) {
  return cognito.resetPassword(email, password, code);
}

export async function getUserAttributes(email, password) {
  return cognito.getUserAttributes(email, password);
}

export async function checkUpdateToken() {
  const oldSession = secretLoad(STORAGE_KEY);
  const cognitoUserSession = await cognito.refreshSession(oldSession);
  if (cognitoUserSession) {
    secretSave(STORAGE_KEY, cognitoUserSession);
    return cognitoUserSession;
  } else {
    return oldSession;
  }
}
