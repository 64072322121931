<template>
  <ModalWrapper ref="wrapper" :is-transparent="true" :is-closeable="true" @open="isActive = true">
    <div class="keyword-modal" :class="{ 'is-active': isActive }">
      <h2 class="keyword-modal__word">{{ keyword.word }}</h2>
      <div class="keyword-modal__description" v-html="keyword.description" />
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '../ModalWrapper';
import eventBus from '@/eventBus';

export default {
  components: {
    ModalWrapper,
  },
  props: {
    keyword: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      eventBus,
    };
  },
  methods: {
    toggle() {
      this.$refs.wrapper && this.$refs.wrapper.toggle();
    },
  },
};
</script>

<style lang="scss" scoped>
.keyword-modal {
  position: absolute;
  bottom: rem(10);
  left: rem(30);
  right: rem(30);
  height: rem(220);

  padding: rem(30) rem(30) rem(30) rem(20);
  overflow: scroll;

  display: flex;
  flex-direction: column;

  background: #4066ea;
  font-family: 'Noto Sans JP', 'proxima-nova', sans-serif;
  color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(#000, 0.25);

  transition: transform 0.2s ease-out;
  transform: translateY(250px);

  &.is-active {
    transform: translateY(0);
  }
  &__word {
    font-size: rem(14);
  }
  &__description {
    margin-top: 25px;
    font-size: rem(12);

    overflow: hidden;
    overflow-y: scroll;
    overflow-wrap: break-word;
  }
}
</style>
