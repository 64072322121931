import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';
import { getSessionToken } from '@/api/cognito';
import { signOut, setSession } from '@/auth';

const Top = () => import('@/views/Top');
const Login = () => import('@/views/Login');
const Signup = () => import('@/views/Signup');
const PasswordReset = () => import('@/views/PasswordReset');
const ChangePassword = () => import('@/views/ChangePassword');
const EmailVerify = () => import('@/views/EmailVerify');
const Policy = () => import('@/views/Policy');
const RegisterUserInfo = () => import('@/views/RegisterUserInfo');
const Dashboard = () => import('@/views/Dashboard');
const ModeSelect = () => import('@/views/ModeSelect');
const SelectNationalExam = () => import('@/views/ModeSelect/NationalExam');
const SelectSubject = () => import('@/views/ModeSelect/Subject');
const SelectUnit = () => import('@/views/ModeSelect/Unit');
const SelectPinpointExam = () => import('@/views/ModeSelect/PinpointExam');
const NationalExam = () => import('@/views/Study/NationalExam');
const NationalExamResult = () => import('@/views/Study/NationalExamResult');
const UnitExam = () => import('@/views/Study/UnitExam');
const UnitExamResult = () => import('@/views/Study/UnitExamResult');
const SubjectExam = () => import('@/views/Study/SubjectExam');
const SubjectExamResult = () => import('@/views/Study/SubjectExamResult');
const PinpointExam = () => import('@/views/Study/PinpointExam');
const PinpointExamResult = () => import('@/views/Study/PinpointExamResult');
const MyPage = () => import('@/views/MyPage');
const MyNote = () => import('@/views/MyPage/MyNote');
const AccountEdit = () => import('@/views/MyPage/AccountEdit');

const routerHistory = createWebHistory();

const routes = [
  {
    name: 'top',
    path: '/',
    component: Top,
  },
  {
    name: 'policy',
    path: '/policy',
    component: Policy,
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
  },
  {
    name: 'logout',
    path: '/logout',
  },
  {
    name: 'signup',
    path: '/signup',
    component: Signup,
  },
  {
    name: 'password-reset',
    path: '/password-reset',
    component: PasswordReset,
  },
  {
    name: 'email-verify',
    path: '/email-verify/:data',
    component: EmailVerify,
    props: true,
  },
  {
    name: 'change-password',
    path: '/change-password:q',
    component: ChangePassword,
    props: true,
  },
  {
    name: 'sso-callback',
    path: '/sso',
    beforeEnter: async (to, from, next) => {
      const { query } = to;
      const { code } = query;
      if (!code) {
        next({ name: 'top' });
        return;
      }

      const { access_token, id_token } = await getSessionToken(code);
      const session = {
        accessToken: {
          jwtToken: access_token,
        },
        idToken: {
          jwtToken: id_token,
        },
      };
      store.dispatch('profile/setSession', { session });
      setSession(session);

      next({ name: 'dashboard' });
    },
  },
  {
    name: 'register-user-info',
    path: '/register-user-info',
    component: RegisterUserInfo,
    props: (route) => ({
      userId: route.query.userId,
      email: route.query.email,
    }),
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
  },
  {
    name: 'mode-select',
    path: '/mode-select',
    component: ModeSelect,
    children: [
      {
        path: 'national-exam/:yearFrom?:yearTo?',
        name: 'select/national-exam',
        component: SelectNationalExam,
        props: true,
      },
      {
        path: 'subject',
        name: 'select/subject',
        component: SelectSubject,
      },
      {
        path: 'subject/:subjectId/:unitId?:exam?:proficiency?',
        name: 'select/unit',
        component: SelectUnit,
        props: true,
      },
      {
        path: 'pinpoint-exam/:keyword?',
        name: 'select/pinpoint-exam',
        component: SelectPinpointExam,
        props: true,
      },
    ],
  },
  {
    name: 'national-exam',
    path: '/national-exam',
    component: NationalExam,
  },
  {
    name: 'unit-exam',
    path: '/unit-exam/:unitId',
    component: UnitExam,
    props: true,
  },
  {
    name: 'subject-exam',
    path: '/subject-exam/:subjectId',
    component: SubjectExam,
    props: true,
  },
  {
    name: 'pinpoint-exam',
    path: '/pinpoint-exam',
    component: PinpointExam,
  },
  {
    name: 'national-exam-result',
    path: '/national-exam/result',
    component: NationalExamResult,
    props: true,
  },
  {
    name: 'unit-exam-result',
    path: '/unit-exam/result',
    component: UnitExamResult,
    props: true,
  },
  {
    name: 'subject-exam-result',
    path: '/subject-exam/result',
    component: SubjectExamResult,
    props: true,
  },
  {
    name: 'pinpoint-exam-result',
    path: '/pinpoint-exam/result',
    component: PinpointExamResult,
  },
  {
    name: 'my-page',
    path: '/my',
    component: MyPage,
  },
  {
    name: 'my-note',
    path: '/my-note/:subjectId',
    component: MyNote,
    props: true,
  },
  {
    name: 'word-detail',
    path: '/my-note/:subjectId:termId',
    component: MyNote,
    props: true,
  },
  {
    name: 'my-note-date',
    path: '/my-note-date/:date',
    component: MyNote,
    props: true,
  },
  {
    name: 'account-edit',
    path: '/account-edit',
    component: AccountEdit,
  },
];

const router = createRouter({
  history: routerHistory,
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'logout') {
    await signOut();
    next({ name: 'top' });
    return;
  }
  next();
});

export default router;
