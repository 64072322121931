<template>
  <transition name="modal-fade" @after-enter="$emit('open')" @after-leave="$emit('close')">
    <div
      v-if="isShow"
      class="modal-wrapper"
      :class="{ 'white-theme': isWhite, 'is-transparent': isTransparent }"
      @click.stop="onClick"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isCloseable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    onClick() {
      if (this.isCloseable) {
        this.isShow = false;
      }
    },
    toggle() {
      this.isShow = !this.isShow;
    },
    close() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.modal-wrapper {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: rem(768);
  margin: 0 auto;

  background: rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;

  &.white-theme {
    background: rgba(#fff, 0.85);
  }

  &.is-transparent {
    background: transparent;
  }
}
</style>
