export const types = {
  SET_SUBJECTS: 'SET_SUBJECTS',
};

export const initialState = {
  subjects: [],
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    subjects: (state) => state.subjects,
  },
  actions: {
    setSubjects({ commit }, { subjects }) {
      commit(types.SET_SUBJECTS, { subjects });
    },
  },
  mutations: {
    [types.SET_SUBJECTS](state, { subjects }) {
      state.subjects = [...subjects];
    },
  },
};
