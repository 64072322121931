import 'cross-fetch/polyfill';
import { CognitoUserPool, CognitoUserAttribute, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
});

let cognitoUser = null;

export function signUp(email, password, firstName, lastName, universityId, universityGrade) {
  const emailAttr = new CognitoUserAttribute({ Name: 'email', Value: email });
  const givenNameAttr = new CognitoUserAttribute({ Name: 'given_name', Value: firstName });
  const familyNameAttr = new CognitoUserAttribute({ Name: 'family_name', Value: lastName });
  const univAttr = new CognitoUserAttribute({ Name: 'custom:university', Value: universityId });
  const univGradeAttr = new CognitoUserAttribute({ Name: 'custom:grade', Value: universityGrade.toString() });

  const attributes = [emailAttr, givenNameAttr, familyNameAttr, univAttr, univGradeAttr];

  return new Promise((res, rej) => {
    userPool.signUp(email, password, attributes, null, (err, result) => {
      if (err) {
        rej(err);
        return;
      }
      res(result);
      return;
    });
  });
}

export function verifyCode(email, code) {
  return new Promise((res, rej) => {
    cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        rej(err);
        return;
      }
      res(result);
    });
  });
}

export function resendVerifyCode(email) {
  cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
  return new Promise((res, rej) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        rej(err);
        return;
      }
      res(result);
    });
  });
}

export function signIn(email, password) {
  cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
  const authDetails = new AuthenticationDetails({ Username: email, Password: password });

  return new Promise((res, rej) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session) => {
        res(session);
      },
      onFailure: (error) => {
        rej(error);
      },
    });
  });
}

export function signOut() {
  if (cognitoUser === null) return;
  cognitoUser.signOut();
}

export function forgotPassword(email) {
  const user = new CognitoUser({ Username: email, Pool: userPool });

  return new Promise((res, rej) => {
    user.forgotPassword({
      onSuccess: (data) => {
        res(data);
      },
      onFailure: (error) => {
        rej(error);
      },
    });
  });
}

export function resetPassword(email, newPassword, verificationCode) {
  const user = new CognitoUser({ Username: email, Pool: userPool });
  return new Promise((res, rej) => {
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: (data) => {
        res(data);
      },
      onFailure: (error) => {
        rej(error);
      },
    });
  });
}

export function getUserAttributes(email, password) {
  cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
  const authDetails = new AuthenticationDetails({ Username: email, Password: password });

  return new Promise((res, rej) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: async () => {
        cognitoUser.getUserAttributes((err, result) => {
          if (err) {
            rej(err);
            return;
          }
          res(result);
        });
      },
      onFailure: (error) => {
        rej(error);
      },
    });
  });
}

export function refreshSession(oldSession) {
  if (cognitoUser === null) return;
  const exp = oldSession.idToken.payload.exp * 1000;
  const now = new Date().getTime();
  if (now <= exp) return;

  // idTokenの有効期限が切れていたら更新する
  return new Promise((res, rej) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        rej(err);
      } else {
        res(session);
      }
    });
  });
}

export function getAuthProviderUrl(provider) {
  const endpoint = `${process.env.VUE_APP_COGNITO_URL}/oauth2/authorize`;

  const params = new URLSearchParams();
  params.append('identity_provider', provider);
  params.append('redirect_uri', process.env.VUE_APP_COGNITO_REDIRECT_URL);
  params.append('response_type', 'CODE');
  params.append('client_id', process.env.VUE_APP_COGNITO_CLIENT_ID);
  params.append('scope', 'aws.cognito.signin.user.admin email openid profile');

  return `${endpoint}?${params}`;
}
