<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `dentistudy - ${content}` : `dentistudy` }}</template>
  </metainfo>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="page-fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <transition name="page-fade">
      <div v-if="isLoading" class="loader">
        <Spinner />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/Spinner';
export default {
  components: {
    Spinner,
  },
  computed: {
    ...mapGetters({
      isLoading: 'app/isLoading',
    }),
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

body {
  user-select: none;
  background: #e5e5e5;
  color: $intelligence-black;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
  font-feature-settings: 'palt' 1;
  font-family: 'Noto Sans JP', 'Hiragino Sans', 'proxima-nova', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  width: 100vw;
  height: 100vh;
  max-width: rem(768);
  margin-left: auto;
  margin-right: auto;
}

.page-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease-out;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(#fff, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: inherit;
  text-decoration: none;
}
</style>
