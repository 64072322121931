import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';

export const types = {
  SET_UNIVERSITIES: 'SET_UNIVERSITIES',
};

export const initialState = {
  universities: [],
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    universities: (state) => orderBy(state.universities, 'order'),
    universityMap: (state) => keyBy(state.universities, 'id'),
  },
  actions: {
    setUniversities({ commit }, { universities }) {
      commit(types.SET_UNIVERSITIES, { universities });
    },
  },
  mutations: {
    [types.SET_UNIVERSITIES](state, { universities }) {
      state.universities = [...universities];
    },
  },
};
