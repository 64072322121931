export const types = {
  SET_EXAM_PARAMS: 'SET_EXAM_PARAMS',
};

export const initialState = {
  examParams: {},
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    examParams: (state) => state.examParams,
  },
  actions: {
    setExamParams({ commit }, { params }) {
      commit(types.SET_EXAM_PARAMS, { params });
    },
  },
  mutations: {
    [types.SET_EXAM_PARAMS](state, { params }) {
      state.examParams = params;
    },
  },
};
