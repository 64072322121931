import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

import router from './router';
import App from './App.vue';
import store from './store';

import ToastPlugin from './plugins/toast-plugin';
import ModalPlugin from './plugins/modal-plugin';
import i18n from './i18n';

import { getSession } from '@/auth';

import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';

dayjs.locale('ja');
const vue = createApp(App);

vue.use(ToastPlugin);
vue.use(ModalPlugin);
vue.use(VueCroppie);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'dentistudy.vercel.app', 'dentistudy-dev.vercel.app', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

vue.use(router);
vue.use(store);
vue.use(i18n);
vue.use(createMetaManager());

vue.mount('#app');

// Load session.
(() => {
  const session = getSession();
  if (session === null) return;
  store.dispatch('profile/setSession', { session });
})();

// Register service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('worker.js');
}

export default vue;
