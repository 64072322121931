<template>
  <ModalWrapper ref="wrapper" :is-transparent="true" :is-closeable="true" @close="eventBus.$emit('close')">
    <div class="tooltip" :style="style">
      <button class="tooltip__tool-blue" @click.stop="eventBus.$emit('blue')"></button>
      <button class="tooltip__tool-red" @click.stop="eventBus.$emit('red')"></button>
      <button class="tooltip__action-button" @click.stop="eventBus.$emit('copy')">
        <CopyIcon />
      </button>
      <button class="tooltip__action-button" @click.stop="eventBus.$emit('edit')">
        <EditIcon />
      </button>
      <button class="tooltip__action-button" @click.stop="eventBus.$emit('search')">
        <SearchIcon />
      </button>
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '../ModalWrapper';
import CopyIcon from '@/assets/images/copy-icon.svg';
import EditIcon from '@/assets/images/edit-icon.svg';
import SearchIcon from '@/assets/images/search-icon.svg';
import eventBus from '@/eventBus';

export default {
  components: {
    ModalWrapper,
    CopyIcon,
    EditIcon,
    SearchIcon,
  },
  props: {
    x: { type: Number, required: true },
    y: { type: Number, required: true },
  },
  data() {
    return {
      eventBus,
    };
  },
  computed: {
    style() {
      return {
        position: 'absolute',
        top: `${this.y}px`,
        left: `${this.x}px`,
      };
    },
  },
  methods: {
    toggle() {
      this.$refs.wrapper && this.$refs.wrapper.toggle();
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  z-index: 999;
  background: #fff;
  position: relative;
  border: none;
  border-radius: 30px;

  min-width: rem(100);
  height: rem(58);
  padding: 0 rem(30);

  box-shadow: 0 0 10px rgba(#000000, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;

  &__action-button {
    @extend %flat-button;
    flex: 0 0 auto;
    width: rem(26);
    height: rem(26);
    background: #fff;

    margin-right: rem(15);
  }

  &__action-button:last-child {
    margin-right: 0;
  }

  &__tool-blue {
    @extend %flat-button;
    flex: 0 0 auto;
    width: rem(26);
    height: rem(26);
    background: #4066ea;
    border-radius: 50%;

    margin-right: rem(10);
  }

  &__tool-red {
    @extend %flat-button;
    flex: 0 0 auto;
    width: rem(26);
    height: rem(26);
    background: #ff6262;
    border-radius: 50%;

    margin-right: rem(26);
  }
}
</style>
