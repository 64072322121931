<template>
  <ModalWrapper ref="wrapper" :is-white="true" @close="close" @open="isPlay = true">
    <FailedIcon :is-play="isPlay" />
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '../ModalWrapper';
import FailedIcon from './FailedIcon';
import eventBus from '@/eventBus';

export default {
  components: {
    ModalWrapper,
    FailedIcon,
  },
  data() {
    return {
      isPlay: false,
      eventBus,
    };
  },
  methods: {
    toggle() {
      this.$refs.wrapper.toggle();
    },
    close() {
      this.isPlay = false;
      this.$refs.wrapper.close();
    },
  },
};
</script>
