import { info, error } from './service';

function createDialogDiv() {
  const dialogDiv = document.createElement('div');
  dialogDiv.id = 'toast-section';
  dialogDiv.style.position = 'fixed';
  dialogDiv.style.top = 0;
  dialogDiv.style.left = 0;
  dialogDiv.style.right = 0;
  dialogDiv.style.zIndex = 9999999;
  dialogDiv.style.display = 'flex';
  dialogDiv.style.alignItems = 'center';
  dialogDiv.style.flexDirection = 'column';
  return dialogDiv;
}

export default {
  install(Vue) {
    const dialogDiv = createDialogDiv();

    document.body.appendChild(dialogDiv);

    Vue.config.globalProperties.$toast = {
      info,
      error,
    };
  },
};
