import { success, failed, keyword, keywordTooltip } from './service';

export default {
  install(Vue) {
    Vue.config.globalProperties.$modal = {
      success,
      failed,
      keyword,
      keywordTooltip,
    };
  },
};
