import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';

export const types = {
  SET_SESSION: 'SET_SESSION',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_DASHBOARD_INFO: 'SET_DASHBOARD_INFO',
  SET_NATIONAL_EXAM_INFO: 'SET_NATIONAL_EXAM_INFO',
  SET_SUBJECT_INFO: 'SET_SUBJECT_INFO',
  SET_TARGET_STUDYTIME_INFO: 'SET_TARGET_STUDYTIME_INFO',
  SET_USER_PHOTO: 'SET_USER_PHOTO',
  SET_MODE_SELECT_INFO: 'SET_MODE_SELECT_INFO',
};

export const initialState = {
  session: null,
  userInfo: null,
  dashboardInfo: null,
  nationalExamInfo: null,
  subjectInfo: null,
  targetStudyTimeInfo: null,
  userPhoto: null,
  modeSelectInfo: null,
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    session: (state) => state.session,
    userInfo: (state) => state.userInfo,
    dashboardInfo: (state) => state.dashboardInfo,
    nationalExamInfo: (state) => state.nationalExamInfo,
    subjectInfo: (state) => state.subjectInfo,
    isAuthenticated: (state) => !!state.session,
    idToken: (state) => (state.session !== null ? state.session.idToken.jwtToken : null),
    accessToken: (state) => (state.session !== null ? state.session.accessToken.jwtToken : null),
    userName(state) {
      if (state.userInfo === null) return null;
      return `${state.userInfo.lastName} ${state.userInfo.firstName}`;
    },
    universityId(state) {
      if (state.userInfo === null) return null;
      return state.userInfo.universityId;
    },
    universityName(state) {
      if (state.userInfo === null) return null;
      return state.userInfo.universityName;
    },
    nationalExamYears(state) {
      if (state.nationalExamInfo === null) return [];
      return state.nationalExamInfo.years;
    },
    nationalExamLastStudy(state) {
      if (state.nationalExamInfo === null) return [];
      if (state.nationalExamInfo.laststudy === '') return '';
      const date = new Date(state.nationalExamInfo.laststudy);
      return date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
    },
    weeklyStudyTime(state) {
      if (state.dashboardInfo === null) return 0;
      const { sun, mon, tue, wed, thu, fri, sat } = state.dashboardInfo.weekstatus;
      return BigNumber(sun[0] + mon[0] + tue[0] + wed[0] + thu[0] + fri[0] + sat[0])
        .div(60)
        .dp(2)
        .decimalPlaces(0)
        .toNumber();
    },
    totalStudyTime(state) {
      if (state.dashboardInfo === null) return 0;
      return BigNumber(state.dashboardInfo.studytime)
        .div(60 * 60)
        .dp(2)
        .decimalPlaces(0)
        .toNumber();
    },
    averageStudyTime(state) {
      if (state.dashboardInfo === null) return 0;

      const { signedup, studytime } = state.dashboardInfo;
      const signedUpDate = dayjs(signedup);
      const nowDate = dayjs();
      const daysElapsed = 1 + dayjs(nowDate).diff(signedUpDate, 'd');

      const result = new BigNumber(studytime).div(60).div(daysElapsed).dp(2);

      return result.isNaN() ? 0 : result.toNumber();
    },
    totalStudyCount(state) {
      if (state.dashboardInfo === null) return 0;
      return state.dashboardInfo.studynum;
    },
    continuousLearningDays(state) {
      if (state.dashboardInfo === null) return 0;
      return state.dashboardInfo.continuous_days;
    },
    weekStatus(state) {
      // NOTE api dependent.
      const labels = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      const upperLabels = labels.map((x) => x.toUpperCase());
      const date = new Date();
      const beginning = date.toISOString();

      if (state.dashboardInfo === null)
        return { labels: upperLabels, time: [0, 0, 0, 0, 0, 0, 0], count: [0, 0, 0, 0, 0, 0, 0], beginning: beginning };
      return {
        labels: upperLabels,
        time: labels.map((x) => state.dashboardInfo.weekstatus[x][0]),
        count: labels.map((x) => state.dashboardInfo.weekstatus[x][1]),
        beginning: state.dashboardInfo.weekstatus.beginning,
      };
    },
    universityGrade(state) {
      if (state.userInfo === null) return null;
      const grade = state.userInfo.grade;
      if (grade == 1) {
        return '1ST';
      } else if (grade == 2) {
        return '2ND';
      } else {
        return String(grade) + 'TH';
      }
    },
    lastStudyDate(state) {
      if (state.dashboardInfo === null) return '';
      if (state.dashboardInfo.laststudy === '') return '';
      const date = new Date(state.dashboardInfo.laststudy);
      return date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
    },
    categoryLevel(state) {
      if (state.dashboardInfo === null) return 0;
      return state.dashboardInfo.category_status.level;
    },
    categoryDP(state) {
      if (state.dashboardInfo === null) return 0;
      return state.dashboardInfo.category_status.dp;
    },
    categoryDpForNextLevel(state) {
      if (state.dashboardInfo === null) return 0;
      return state.dashboardInfo.category_status.dp_for_next_lv;
    },
    nationalExamRank(state) {
      if (state.dashboardInfo === null) return 0;
      return state.dashboardInfo.national_status.rank;
    },
    nationalExamDmg(state) {
      if (state.dashboardInfo === null) return 0;
      return state.dashboardInfo.national_status.dmg;
    },
    nationalExamDmgForNextLevel(state) {
      if (state.dashboardInfo === null) return 0;
      return state.dashboardInfo.national_status.dmg_for_next_rank;
    },
    targetStudyTimeInfo: (state) => state.targetStudyTimeInfo,
    targetStudyTime(state) {
      if (state.targetStudyTimeInfo === null) return 0;
      return state.targetStudyTimeInfo.target_studytime;
    },
    userPhoto: (state) => state.userPhoto,
    userPhotoUri(state) {
      if (state.userPhoto === null) return '';
      return state.userPhoto.photo_uri;
    },
    modeSelectInfo: (state) => state.modeSelectInfo,
    categoryNumStudy(state) {
      if (state.modeSelectInfo === null) return 0;
      return state.modeSelectInfo.category_status.num_study;
    },
    nationalNumStudy(state) {
      if (state.modeSelectInfo === null) return 0;
      return state.modeSelectInfo.national_status.num_study;
    },
    pinpointNumStudy(state) {
      if (state.modeSelectInfo === null) return 0;
      return state.modeSelectInfo.pinpoint_status.num_study;
    },
    daysUntilNationalExam(state) {
      if (state.modeSelectInfo === null) return 0;
      return state.modeSelectInfo.days_until_national_exam;
    },
  },
  actions: {
    setSession({ commit }, { session }) {
      commit(types.SET_SESSION, { session });
    },
    setUserInfo({ commit }, { userInfo }) {
      commit(types.SET_USER_INFO, { userInfo });
    },
    setDashboardInfo({ commit }, { dashboardInfo }) {
      commit(types.SET_DASHBOARD_INFO, { dashboardInfo });
    },
    setNationalExamInfo({ commit }, { nationalExamInfo }) {
      commit(types.SET_NATIONAL_EXAM_INFO, { nationalExamInfo });
    },
    setSubjectInfo({ commit }, { subjectInfo }) {
      commit(types.SET_SUBJECT_INFO, { subjectInfo });
    },
    setTargetStudyTimeInfo({ commit }, { targetStudyTimeInfo }) {
      commit(types.SET_TARGET_STUDYTIME_INFO, { targetStudyTimeInfo });
    },
    setUserPhoto({ commit }, { userPhoto }) {
      commit(types.SET_USER_PHOTO, { userPhoto });
    },
    setModeSelectInfo({ commit }, { modeSelectInfo }) {
      commit(types.SET_MODE_SELECT_INFO, { modeSelectInfo });
    },
  },
  mutations: {
    [types.SET_SESSION](state, { session }) {
      state.session = session;
    },
    [types.SET_USER_INFO](state, { userInfo }) {
      state.userInfo = userInfo;
    },
    [types.SET_DASHBOARD_INFO](state, { dashboardInfo }) {
      state.dashboardInfo = dashboardInfo;
    },
    [types.SET_NATIONAL_EXAM_INFO](state, { nationalExamInfo }) {
      state.nationalExamInfo = nationalExamInfo;
    },
    [types.SET_SUBJECT_INFO](state, { subjectInfo }) {
      state.subjectInfo = subjectInfo;
    },
    [types.SET_TARGET_STUDYTIME_INFO](state, { targetStudyTimeInfo }) {
      state.targetStudyTimeInfo = targetStudyTimeInfo;
    },
    [types.SET_USER_PHOTO](state, { userPhoto }) {
      state.userPhoto = userPhoto;
    },
    [types.SET_MODE_SELECT_INFO](state, { modeSelectInfo }) {
      state.modeSelectInfo = modeSelectInfo;
    },
  },
};
