export const types = {
  SET_REGISTER_DATES: 'SET_REGISTER_DATES',
  SET_MY_NOTES_BY_SUBJECT: 'SET_MY_NOTES_BY_SUBJECT',
  SET_MY_NOTES_BY_DATE: 'SET_MY_NOTES_BY_DATE',
  SET_MY_MEMOS: 'SET_MY_MEMOS',
};

export const initialState = {
  registerDates: [],
  myNotesBySubject: [],
  myNotesByDate: [],
  myMemos: [],
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    registerDates: (state) => state.registerDates,
    myNotesBySubject: (state) => state.myNotesBySubject,
    myNotesByDate: (state) => state.myNotesByDate,
    myMemos: (state) => state.myMemos,
  },
  actions: {
    setRegisterDates({ commit }, { dates }) {
      commit(types.SET_REGISTER_DATES, { dates });
    },
    setMyNotesBySubject({ commit }, { myNotes }) {
      commit(types.SET_MY_NOTES_BY_SUBJECT, { myNotes });
    },
    setMyNotesByDate({ commit }, { myNotes }) {
      commit(types.SET_MY_NOTES_BY_DATE, { myNotes });
    },
    setMyMemos({ commit }, { memos }) {
      commit(types.SET_MY_MEMOS, { memos });
    },
  },
  mutations: {
    [types.SET_REGISTER_DATES](state, { dates }) {
      state.registerDates = [...dates];
    },
    [types.SET_MY_NOTES_BY_SUBJECT](state, { myNotes }) {
      state.myNotesBySubject = myNotes;
    },
    [types.SET_MY_NOTES_BY_DATE](state, { myNotes }) {
      state.myNotesByDate = myNotes;
    },
    [types.SET_MY_MEMOS](state, { memos }) {
      state.myMemos = memos;
    },
  },
};
