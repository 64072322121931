<template>
  <ModalWrapper ref="wrapper" :is-white="true" @close="close" @open="isPlay = true">
    <SuccessIcon :is-play="isPlay" />
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '../ModalWrapper';
import SuccessIcon from './SuccessIcon';

export default {
  components: {
    ModalWrapper,
    SuccessIcon,
  },
  data() {
    return {
      isPlay: false,
    };
  },
  methods: {
    toggle() {
      this.$refs.wrapper.toggle();
    },
    close() {
      this.isPlay = false;
      this.$refs.wrapper.close();
    },
  },
};
</script>
