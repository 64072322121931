import axios from 'axios';
import store from '@/store';

const client = axios.create({ baseURL: process.env.VUE_APP_COGNITO_URL });

client.interceptors.response.use((res) => res.data);

/**
 * 外部プロバイダーを使用した認証コードからセッションを取得
 * @param {*} code
 */
export async function getSessionToken(code) {
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('client_id', process.env.VUE_APP_COGNITO_CLIENT_ID);
  params.append('redirect_uri', process.env.VUE_APP_COGNITO_REDIRECT_URL);
  params.append('code', code);

  return await client.post('/oauth2/token', params);
}

/**
 * Cognitoに登録されているユーザー情報を取得する
 */
export async function getUserInfoByCognito() {
  const accessToken = store.getters['profile/accessToken'];
  return client.get('/oauth2/userInfo', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export default client;
