import { createStore as _createStore } from 'vuex';

import university from './modules/university';
import profile from './modules/profile';
import subject from './modules/subject';
import myNote from './modules/my-note';
import progress from './modules/progress';
import study from './modules/study';
import app from './modules/app';

export default _createStore({
  modules: {
    university,
    profile,
    subject,
    myNote,
    progress,
    study,
    app,
  },
});
