import { h, render } from 'vue';
import ErorrToast from './ErrorToast';
import InfoToast from './InfoToast';

function activate(vueInstance, activeMillisecound) {
  const container = document.getElementById('toast-section');
  render(vueInstance, container);
  vueInstance.component.ctx.toggle();

  if (activeMillisecound) {
    setTimeout(() => {
      if (vueInstance) {
        vueInstance.component.ctx.close();
      }
    }, activeMillisecound);
  }
}

export function error(message, activeMillisecound = 0) {
  const props = {
    text: message,
  };
  const instance = h(ErorrToast, props);
  activate(instance, activeMillisecound);
}

export function info(message, activeMillisecound = 0) {
  const props = {
    text: message,
  };
  const instance = h(InfoToast, props);
  activate(instance, activeMillisecound);
}
