import { h, render } from 'vue';

import SuccessEffectModal from './SuccessEffectModal';
import FailedEffectModal from './FailedEffectModal';
import KeywordModal from './KeywordModal';
import KeywordTooltip from './KeywordTooltipModal';
import eventBus from '@/eventBus.js';

const autoCloseMillSec = 800;

function activate(vueInstance) {
  render(vueInstance, document.body);
  vueInstance.component.ctx.toggle();
}

function activateForAnimationModal(vueInstance) {
  return new Promise((res) => {
    render(vueInstance, document.body);
    vueInstance.component.ctx.toggle();
    setTimeout(() => {
      vueInstance.component.ctx.close();
      res();
    }, autoCloseMillSec);
  });
}

function activateForKeywordTooltipModal(vueInstance) {
  render(vueInstance, document.body);
  vueInstance.component.ctx.toggle();
  return new Promise((res) => {
    let closed = false;
    eventBus.$once('close', () => {
      if (!closed) {
        res(undefined);
      }
    });
    eventBus.$once('copy', () => {
      res('copy');
      closed = true;
      vueInstance.component.ctx.toggle();
    });
    eventBus.$once('edit', () => {
      res('edit');
      closed = true;
      vueInstance.component.ctx.toggle();
    });
    eventBus.$once('search', () => {
      res('search');
      closed = true;
      vueInstance.component.ctx.toggle();
    });
    eventBus.$once('blue', () => {
      res('blue');
      closed = true;
      vueInstance.component.ctx.toggle();
    });
    eventBus.$once('red', () => {
      res('red');
      closed = true;
      vueInstance.component.ctx.toggle();
    });
  });
}

export function success() {
  const instance = h(SuccessEffectModal);
  return activateForAnimationModal(instance);
}

export function failed() {
  const instance = h(FailedEffectModal);
  return activateForAnimationModal(instance);
}

export function keyword(keyword) {
  const props = {
    keyword,
  };
  const instance = h(KeywordModal, props);
  return activate(instance);
}

export function keywordTooltip(x, y) {
  const props = {
    x,
    y,
  };
  const instance = h(KeywordTooltip, props);
  return activateForKeywordTooltipModal(instance);
}
